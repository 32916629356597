<form *ngVar="'SHARED.ADDRESS_FORM.' as translations" [ngrxFormState]="formState" (submit)="submitClicked()"
  (ngrxFormsAction)="ngrxFormsActionEmitted($event)" class="address-form">
  <div *ngIf="!isPartialMode" class="row address-form-row">
    <div class="col col-stretch">
      <form-group [formControlState]="formState.controls.address" [isLoading]="isGeocoding">
        <form-control-address [ngrxFormControlState]="formState.controls.address" [addressState]="formState"
          [placeholder]="translations + 'TEXT_ADDRESS_PLACEHOLDER' | translate"
          (ngrxFormsAction)="ngrxFormsActionEmitted($event)" (isGeocodingChanged)="isGeocoding = $event">
        </form-control-address>
        <div *ngIf="isAddressInvalid" class="form-error">
          {{ translations + 'TEXT_ADDRESS_INVALID' | translate }}
        </div>
      </form-group>
    </div>
  </div>
  <div class="row address-form-row">
    <div class="col col-50">
      <form-group [formControlState]="formState.controls.apt">
        <form-control [ngrxFormControlState]="formState.controls.apt"
          [placeholder]="translations + 'TEXT_APT_PLACEHOLDER' | translate"
          (ngrxFormsAction)="ngrxFormsActionEmitted($event)">
        </form-control>
      </form-group>
    </div>
    <div class="col col-50">
      <form-group [formControlState]="formState.controls.gatecode">
        <form-control [ngrxFormControlState]="formState.controls.gatecode"
          [placeholder]="translations + 'TEXT_GATECODE_PLACEHOLDER' | translate"
          (ngrxFormsAction)="ngrxFormsActionEmitted($event)">
        </form-control>
      </form-group>
    </div>
  </div>
  <form-group [formControlState]="formState.controls.buildingName" class="address-form-row">
    <form-control [ngrxFormControlState]="formState.controls.buildingName"
      [placeholder]="translations + 'TEXT_BUILDING_NAME_PLACEHOLDER' | translate"
      (ngrxFormsAction)="ngrxFormsActionEmitted($event)">
    </form-control>
  </form-group>
  <form-group *ngIf="!isPartialMode" class="address-form-row">
    <checkbox-form-control *ngIf="showSetDefaultOption" [ngrxFormControlState]="formState.controls.isActive"
      (ngrxFormsAction)="ngrxFormsActionEmitted($event)">
      {{ translations + 'TEXT_SET_AS_DEFAULT' | translate }}
    </checkbox-form-control>
  </form-group>
  <ng-container *ngIf="buttonsTemplate; else defaultButtonsTemplate" [ngTemplateOutlet]="buttonsTemplate">
  </ng-container>
  <ng-template #defaultButtonsTemplate>
    <div class="row address-form-gap">
      <div class="col col-100">
        <app-button [isDisabled]="isLoading" [isBlock]="true" [isOutline]="true" variant="primary"
          (clicked)="cancelClicked()">
          {{ translations + 'BUTTON_CANCEL' | translate }}
        </app-button>
      </div>
      <div class="col col-100">
        <app-button [isLoading]="isLoading" [isBlock]="true" variant="purple" type="submit">
          {{ translations + ((isEditMode) ? 'BUTTON_SAVE_CHANGES' : 'BUTTON_ADD_ADDRESS') | translate }}
        </app-button>
      </div>
    </div>
  </ng-template>
</form>
