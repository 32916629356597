<ng-container *ngVar="'SHARED.ORDER_ITEMS.CATEGORY.' as translations">
  <collapsible-field *ngVar="selectedCategoryItems | itemsCount as itemsCount" [isCollapsed]="isCollapsed"
    [title]="category.name"
    [info]="(itemsCount) ? (translations + 'TEXT_SELECTED' | translate: { count: itemsCount }) : ''"
    (opened)="opened.emit()" (closed)="closed.emit()">
    <div *ngIf="category.emoji" side [ngStyle]="{ backgroundColor: category.emojiBackgroundColor || '' }"
      class="category-icon">
      {{ category.emoji }}
    </div>
    <div class="category-cards" [class.collapsed]="isExpanded" [ngClass]="category.name==='Most Popular'?'category-first-row':''">
      <order-items-item *ngFor="let item of category.items; trackByProperty: 'id'" [item]="item"
        [count]="item | map : getItemCount : selectedCategoryItems" [isReadonly]="isReadonly"
        (countDecreased)="decreaseItemCountButtonClicked(item)" (countIncreased)="increaseItemCountButtonClicked(item)">
      </order-items-item>
    </div>
    <div *ngIf="category.items.length>5 && category.name!=='Most Popular' " class="category-items-show-hide">
    <app-button  [isFlat]="true" variant="purple" textSize="inherit" (click)="scrollTop(isExpanded)"
                (clicked)="isExpanded = !isExpanded"     class="why-service-button hidden-mobile">
      {{ translations + ((isExpanded) ? 'BUTTON_HIDE' : 'BUTTON_SHOW_MORE') | translate }}
    </app-button>

      <app-button  [isBlock]="true"  variant="purple" textSize="inherit" (click)="scrollTop(isExpanded)"
                (clicked)="isExpanded = !isExpanded"     class="why-service-button visible-mobile">
      {{ translations + ((isExpanded) ? 'BUTTON_HIDE' : 'BUTTON_SHOW_MORE') | translate }}
    </app-button>

    </div>
  </collapsible-field>

</ng-container>
