<div [ngClass]="['info-field-' + variant]" [class.info-field-underline]="underline"
  [class.info-field-clickable]="isClickable" (click)="isClickable && fieldClicked()" class="info-field" >
  <div *ngIf="name && iconType" class="info-field-name">
    {{ name }}
  </div>
  <div class="row info-field-content" >
    <div class="col col-stretch">
      <div [class.loading]="isLoading" class="row info-field-header loading-primary">
        <div *ngIf="iconType" class="col col-content"  [class.info-field-hide]="hideIconMobile">
          <icon-background *ngIf="iconBackground; else iconTemplate" [variant]="iconBackground">
            <ng-container [ngTemplateOutlet]="iconTemplate"></ng-container>
          </icon-background>
          <ng-template #iconTemplate>
            <icon [type]="iconType" [color]="iconColor"></icon>
          </ng-template>
        </div>
        <div class="col col-stretch info-field-info">
          <div *ngIf="name && !iconType" class="info-field-name">{{ name }}</div>
          <div *ngIf="title" class="info-field-title">{{ title }}</div>
          <div *ngIf="info" [ngClass]="'info-field-info-' + infoSize">{{ info }}</div>
        </div>
      </div>
      <ng-content></ng-content>
    </div>
    <div   [class.centered]="isSideCentered" class="col col-content info-field-side">
      <div *ngIf="hasCost && !isNewMode" class="info-field-cost">{{ (cost || 0) | currency }}</div>
      <ng-content select=".info-field-action"></ng-content>
    </div>

  </div>
  <ng-content select="[footer]"></ng-content>
</div>
