<dialog-view *ngVar="'SHARED.ADDRESS_PICKER.' as translations" class="address-picker">
  <ng-container *ngVar="(isLoading$ | async) as isLoading">
    <dialog-header variant="underline">
      <dialog-content class="address-picker-content">

      <span *ngIf="!isServiceNew"> {{ translations + 'TEXT_TITLE' | translate : { isPreferred: !order } }}</span>
      <span *ngIf="isServiceNew"> {{ translations + 'TEXT_ENTER_NEW_ADDRESS_TITLE' | translate }}</span>

        <dialog-close [isLoading]="isLoading" (clicked)="close()"></dialog-close>
      </dialog-content>
    </dialog-header>
    <dialog-body>
      <dialog-content class="address-picker-content">
        <div class="address-picker-list" *ngIf="!isServiceNew ||  ( isServiceNew && !isUpserting)">
          <form-group *ngFor="let address of addresses; trackByProperty: 'id'; let i = index;">
            <div class="address-picker-option" >
              <ng-container *ngIf="i === 0 && addresses?.length === 1">
              <radio  [isSelected]="selectedAddress?.id === address.id" name="addresses"
                (radioChanged)="$event && addressSelected(address)">
                <span class="address-picker-location">
                  <span *ngIf="isEditing && selectedAddress?.id === address.id" class="address-picker-edit">
                    {{ translations + 'TEXT_EDIT' | translate }}
                  </span>
                  {{ address | addressInfo }}
                </span>
              </radio>
              </ng-container>
              <radio *ngIf="i > 0 " [isSelected]="selectedAddress?.id === address.id" name="addresses"
                     (radioChanged)="$event && addressSelected(address)">
                <span class="address-picker-location">
                  <span *ngIf="isEditing && selectedAddress?.id === address.id" class="address-picker-edit">
                    {{ translations + 'TEXT_EDIT' | translate }}
                  </span>
                  {{ address | addressInfo }}
                </span>
              </radio>
              <button *ngIf="address?.id !== getFirstZip()?.id" (click)="editClicked(address)" class="button"
                data-test="edit-address">
                <icon type="edit-outline" color="primary"></icon>
              </button>
              <button *ngIf="address?.id !== getFirstZip()?.id" (click)="deleteClicked(address)" class="button"
                data-test="delete-address">
                <icon type="trash" color="error"></icon>
              </button>
            </div>
          </form-group>
          <form-group>
            <div class="address-picker-option" >
              <radio [isSelected]="isAdding" name="locations" (radioChanged)="$event && addAddressOptionClicked()">
                <span class="address-picker-location">
                  {{ translations + 'TEXT_ADD_NEW_ADDRESS' | translate }}
                </span>
              </radio>
            </div>
          </form-group>
        </div>
        <address-form *ngIf="isUpserting " [isLoading]="isLoading" [address]="isEditing && selectedAddress"
          (submitted)="formSubmitted($event)" (cancelled)="formCancelClicked()">
        </address-form>
        <dialog-buttons *ngIf="!isUpserting" class="row">
          <div class="col col-50">
            <app-button [isDisabled]="isLoading" variant="primary" [isOutline]="true" [isBlock]="true"
              (clicked)="!isLoading && close()">
              {{ translations + 'BUTTON_CANCEL' | translate }}
            </app-button>
          </div>
          <div class="col col-50">
            <app-button [isDisabled]="!selectedAddress || isAdding" variant="primary" [isBlock]="true"
              [isLoading]="isLoading" (clicked)="saveButtonClicked()">
              {{ translations + (saveButtonName ? saveButtonName : 'BUTTON_SAVE') | translate }}
            </app-button>
          </div>
        </dialog-buttons>
      </dialog-content>
    </dialog-body>
  </ng-container>
</dialog-view>
