
export const isRedirectToCity = (url: string): boolean => {
  return url?.startsWith('/state') || url?.startsWith('/services/city') || url==='/services';
}

export const isNewDesignNav = (url: string): boolean => {
  return url==='/' || url.includes('/commercial') || url==='/services' || url?.startsWith('/services/city') || url?.startsWith('/locations')|| url=='/?param=%2Ffaq';
}

export const isNewServicesNav = (url: string): boolean => {
  return url==='/services' || url?.startsWith('/services/city');
}

export const isCommercial = (url: string): boolean => {
  return url.includes('/commercial');
}

export const isLocation = (url: string): boolean => {
  return url?.startsWith('/locations');
}

export const hasZipcode = (url: string): boolean => {
  return url.includes('/commercial') || url==='/services' || url?.startsWith('/services/city');
}
