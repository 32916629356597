import {Actions, createEffect, ofType} from '@ngrx/effects';
import type {AppState} from '@shared/store';
import {Injectable} from '@angular/core';
import {Action, Store} from '@ngrx/store';
import {SeoService} from '../seo.service';
import {catchError, filter, map, Observable, of, switchMap, tap, withLatestFrom} from 'rxjs';
import {SeoActions} from './actions';
import {SeoPage, SeoPageHeadingTags, SeoPageService, SeoPageTag, SeoPageTagType, SeoPageType} from '@shared/seo-page';
import {HttpErrorResponse, HttpStatusCode} from '@angular/common/http';
import {getSelectors} from '@ngrx/router-store';
import {ErrorHandlingService} from '@shared/error-handling';

@Injectable()
export class SeoEffects {
  public resetState$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SeoActions.resetState),
      tap(() => this.resetSEO())
    ),
    { dispatch: false }
  );

  public loadSettings$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SeoActions.tryLoadSettings),
      tap(() => this.resetSEO()),
      filter(({ request, defaultSettings }) => !!request?.url && (!!request?.type || !!defaultSettings)),
      tap(() => this.store.dispatch(SeoActions.loadSettings())),
      withLatestFrom(
        this.store.select(getSelectors().selectUrl)
      ),
      switchMap(([{ request, onSuccess, onFailure, defaultSettings }, initialURL]) =>
        ((!!request?.type) ? this.seoPageService.getByURL(request) : of(defaultSettings))
          .pipe(
            withLatestFrom(
              this.store.select(getSelectors().selectUrl)
            ),
            filter(([_, currentURL]) => initialURL === currentURL),
            tap(([settings]) => {
              if (settings?.deletedAt && !settings?.isDefault) {
                if(settings.type===SeoPageType.COMMON){
                  throw new HttpErrorResponse({ status: HttpStatusCode.BadRequest });

                }else{
                  throw new HttpErrorResponse({ status: HttpStatusCode.NotFound });

                }
              }
            }),
            tap(([settings]) => {
              if(settings?.deletedAt){
                defaultSettings= {...defaultSettings,codeHead:settings.codeHead+''+defaultSettings.codeHead}
                if (!defaultSettings) {
                  this.seoService.initSettings(defaultSettings);
                }
                onSuccess?.(defaultSettings);
                if(settings.type===SeoPageType.COMMON){
                  throw new HttpErrorResponse({ status: HttpStatusCode.BadRequest });

                }else{
                  throw new HttpErrorResponse({ status: HttpStatusCode.NotFound });

                }
              }
              else {
                // if(request.url==='/services/wash-and-fold-laundry-services/city/san-diego-ca' || request.url==='/services/wash-and-fold-laundry-services/city/miami-fl'){
                //   settings.url=request.url;
                //   settings=this.initSpecialSetting(settings)
                // }
                onSuccess?.(settings);
                this.seoService.initSettings(settings);
              }
            }),
            map(([settings]) => SeoActions.loadSettingsSuccess({ settings })),
            catchError((response: HttpErrorResponse) => {
              if(response.status===HttpStatusCode.NotFound){
                this.errorHandlingService.handleHttpError(response);
              }
              onFailure?.(response);
              if (defaultSettings) {
                this.seoService.initSettings(defaultSettings);
              }
              return of(SeoActions.loadSettingsFailure({ response, settings: defaultSettings }));
            })
          )
      )
    )
  );
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private seoService: SeoService,
    private seoPageService: SeoPageService,
    private errorHandlingService: ErrorHandlingService,
  ) { }

  private resetSEO(): void {
    this.seoService.reset();
  }
}
