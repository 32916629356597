<div #footer *ngVar="'SHARED.FOOTER.' as translations" class="footer">
  <content>
    <div class="footer-row row">
      <ng-container *ngIf="additionalContentTemplate$ | async as additionalContentTemplate"
                    [ngTemplateOutlet]="additionalContentTemplate">
      </ng-container>
      <ng-container *ngVar="userZip$ | async as zip">
        <ng-container *ngVar="topLocations$ | async as topLocations">

          <div class="col col-100 footer-top-info">
            <div class="col col-100 footer-top-info">
              <div class="row">
                <div class="col col-50 col-mobile-100 footer-nopadding">
                  <div class="footer-cities-service" *ngVar="isAuthenticated$ |async as isAuth">

                    <div *ngIf="!topLocations.length" class="col cal-100 col-mobile-100">
                      <footer-title >
                        {{ translations + 'TEXT_TOP_CITIES' | translate }}
                      </footer-title>
                      <div class="footer-cities">
                        <app-link *ngFor="let city of citiesInLoundryServiceAll"  [routerLink]="city.href" [inheritFont]="true">
                          {{ city.text }}
                        </app-link>
                      </div>

                    </div>

                    <div *ngIf="topLocations.length"  class="col col-100 col-mobile-100">
                      <footer-title >
                        {{ translations + 'TEXT_TOP_CITIES' | translate }}
                      </footer-title>
                      <div class="footer-cities">
                        <app-link *ngFor="let city of topLocations"  [routerLink]="'/services/city/'+city.code" [inheritFont]="true">
                          {{ city.title }}
                        </app-link>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col col-50 col-mobile-100">
                  <footer-title>
                    {{ translations + 'TEXT_TOP_SERVICES' | translate }}
                  </footer-title>
                  <div class="footer-services">
                    <app-link *ngFor="let service of services"
                              routerLink="{{(!!zip?.getLocationOfType(LocationType.CITY)?.code && service.isCityAdd)?
                              service.href+(service.addUrl?service.addUrl:'')+'/city/'+zip.getLocationOfType(LocationType.CITY).code:service.href}}"
                              [inheritFont]="true" color="grays-dark">
                      {{ service.text }}
                    </app-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <div class="col col-50 col-mobile-100 footer-nopadding"  *ngVar="userZip$ | async as zip">
        <div class="row" *ngVar="isAuthenticated$ |async as isAuth">
          <div class="col col-33 col-mobile-50">
            <footer-title>
              {{ translations + 'TEXT_GET_KNOW_US' | translate }}
            </footer-title>
            <div class="footer-link">
              <app-link routerLink="/about-us" [useInheritedFont]="true" color="grays-dark">
                {{ translations + 'LINK_ABOUT' | translate }}
              </app-link>
            </div>
            <div class="footer-link">
              <app-link (click)="scrollToSection()" [useInheritedFont]="true" color="grays-dark">
                {{ translations + 'LINK_FAQ' | translate }}
              </app-link>
            </div>
            <div class="footer-link" *ngIf="!isAuth">
              <app-link routerLink="/locations" [useInheritedFont]="true" color="grays-dark">
                {{ translations + 'LINK_CITIES' | translate }}
              </app-link>
            </div>
            <div class="footer-link">
              <app-link routerLink="/blog" [useInheritedFont]="true" color="grays-dark">
                {{ translations + 'LINK_BLOG' | translate }}
              </app-link>
            </div>
            <div class="footer-link">
              <app-link routerLink="/contact-us" [useInheritedFont]="true" color="grays-dark">
                {{ translations + 'LINK_CONTACT_US' | translate }}
              </app-link>
            </div>
          </div>
          <div class="col col-66 col-mobile-50">
            <footer-title>
              {{ translations + 'TEXT_DOING_BECOME_PARTNER' | translate }}
            </footer-title>
            <div class="footer-link">
              <app-link routerLink="/signup-to-deliver" [useInheritedFont]="true" color="grays-dark">
                {{ translations + 'LINK_BECOME_MEMBER' | translate }}
              </app-link>
            </div>
            <div class="footer-link">
              <app-link routerLink="/become-partner" [useInheritedFont]="true" color="grays-dark">
                {{ translations + 'LINK_BECOME_PARTNER' | translate }}
              </app-link>
            </div>
          </div>
        </div>
      </div>
      <div class="col col-50 col-mobile-100 footer-nopadding">
        <div class="row">
          <div class="col col-100 col-mobile-75">
            <footer-title>
              {{ translations + 'TEXT_GET_APP' | translate }}
            </footer-title>
            <div class="footer-description">
              {{ translations + 'TEXT_HAMPERAPP_DESCRIPTION' | translate }}
              <div class="footer-phone">
<!--                <img #footerSmartphone ngSrc="/assets/images/smartphone-527x1072.webp" width="527" height="1072"-->
<!--                     alt="Hamperapp mobile"/>-->
                <img #footerSmartphone ngSrc="/assets/images/smartphone.webp" width="1046" height="2159"
                     alt="Hamperapp mobile" priority/>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-store-buttons">
          <div class="footer-rate">
            <div class="footer-rate-icons">
              <div>
                <img src="assets/images/app_store.png" alt="App Store">
              </div>
              <div>
                <img src="assets/images/google_play.png" alt="Google Play">
              </div>
            </div>
            <div class="footer-rate-stars">
              <div class="footer-stars">
                <stars [rating]="5" class="rating-stars"></stars>
              </div>
              <p class="footer-rate-reviews">6,000+ reviews</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-nav">
      <div class="footer-nav-item footer-logo">
        <logo variant="secondary"></logo>
      </div>
      <div class="footer-nav-mobile">
        <div class="footer-nav-item footer-nav-terms">
          <app-link routerLink="/do-not-sell-my-info" underline="always" [useInheritedFont]="true" color="grays-silver">
            {{ translations + 'TEXT_CERTIFICATION' | translate }}
          </app-link>
        </div>
        <div class="footer-nav-item footer-nav-terms">
          <app-link routerLink="/privacy-policy" underline="always" [useInheritedFont]="true" color="grays-silver">
            {{ translations + 'LINK_POLICY' | translate }}
          </app-link>
        </div>
        <div class="footer-nav-item footer-nav-terms">
          <app-link routerLink="/legal-terms" underline="always" [useInheritedFont]="true" color="grays-silver">
            {{ translations + 'LINK_TERMS' | translate }}
          </app-link>
        </div>
      </div>

      <div class="footer-nav-item">
        {{ translations + 'TEXT_COPYRIGHT' | translate: {year: currentYear} }}
      </div>
      <div class="footer-nav-item footer-nav-policy">
        <app-link routerLink="/do-not-sell-my-info" underline="always" [useInheritedFont]="true" color="grays-silver">
          {{ translations + 'TEXT_CERTIFICATION' | translate }}
        </app-link>
      </div>
      <div class="footer-nav-item footer-nav-policy">
        <app-link routerLink="/privacy-policy" underline="always" [useInheritedFont]="true" color="grays-silver">
          {{ translations + 'LINK_POLICY' | translate }}
        </app-link>
      </div>
      <div class="footer-nav-item footer-nav-policy">
        <app-link routerLink="/legal-terms" underline="always" [useInheritedFont]="true" color="grays-silver">
          {{ translations + 'LINK_TERMS' | translate }}
        </app-link>
      </div>
      <div class="footer-nav-item footer-socials">
        <a [href]="links.twitter" target="_blank">
          <app-button [isFlat]="true" variant="grays-dark">
            <icon type="twitter" color="default"></icon>
          </app-button>
        </a>
        <a [href]="links.facebook" target="_blank">
          <app-button [isFlat]="true" variant="grays-dark">
            <icon type="facebook" color="default"></icon>
          </app-button>
        </a>
        <a *ngIf="links.linkedin" [href]="links.linkedin" target="_blank">
          <app-button [isFlat]="true" variant="grays-dark">
            <icon type="linkedin" color="default"></icon>
          </app-button>
        </a>
      </div>
    </div>
  </content>
</div>
